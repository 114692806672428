import { MetaFunction } from '@remix-run/node';
import { SignInIndexPage } from '~/pages/signIn';
import { signInAction } from '~/pages/signIn/actions';
import { signInLoader } from '~/pages/signIn/loaders';

export const meta: MetaFunction<typeof loader> = ({ data }) => [
  {
    title: data?.metaTitle,
  },
  {
    name: 'description',
    content: data?.metaDescription,
  },
];

export const loader = signInLoader;
export const action = signInAction;
export default SignInIndexPage;
