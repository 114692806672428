import {
  LegalDisclaimers,
  openNotification,
} from '@elements-financial/elements';
import { Form, useNavigate, useNavigation } from '@remix-run/react';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedActionData, useTypedLoaderData } from 'remix-typedjson';
import Input from '~/components/Input';
import ButtonDeprecated from '~/components/general/ButtonDeprecated';
import { Route } from '~/pages/root/util/path';
import { signInAction } from './actions';
import { SignInPanel } from './components';
import { signInLoader } from './loaders';
import { SignInError } from './types';

export const SignInIndexPage: FC = () => {
  const { state: transitionState } = useNavigation();
  const { t: tError } = useTranslation('error');
  const { t: tSignIn } = useTranslation('signIn');
  const nav = useNavigate();

  const loaderData = useTypedLoaderData<typeof signInLoader>();
  const actionData = useTypedActionData<typeof signInAction>();

  const [email, setEmail] = useState(loaderData.email || '');
  const [password, setPassword] = useState(loaderData.password || '');
  const [error, setError] = useState<SignInError | null>(null);

  const loading = transitionState !== 'idle';

  useEffect(() => {
    if (actionData && 'error' in actionData && actionData?.error) {
      setError(actionData.error);
    }
    if (
      actionData &&
      'unknownError' in actionData &&
      actionData?.unknownError
    ) {
      openNotification({
        type: 'error',
        description: tError('sign-in-generic-error'),
      });
    }
  }, [actionData]);

  useEffect(() => {
    // If email or password has changed then clear the error
    if (email.length > 0 || password.length > 0) {
      setError(null);
    }
  }, [email, password]);

  return (
    <SignInPanel
      footer={
        <>
          {error == SignInError.InvalidCredentials && (
            <div className="text-darkMode-danger mb-8">
              <div>{tSignIn('invalid-credentials-error-heading')}</div>
              <div>{tSignIn('invalid-credentials-error-subheading')}</div>
            </div>
          )}
          <LegalDisclaimers />
        </>
      }
    >
      <Form
        className="space-y-6"
        method="post"
        onSubmit={(e) => {
          if (loading) e.preventDefault();
        }}
      >
        {loaderData.impersonate && (
          <input
            type="hidden"
            name="impersonate"
            value={loaderData.impersonate}
          />
        )}
        <Input
          autoFocus
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
          status={error ? 'error' : undefined}
          disabled={loading}
          label={tSignIn('email')}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <Input
          id="password"
          name="password"
          specialization="password"
          autoComplete="current-password"
          required
          status={error ? 'error' : undefined}
          disabled={loading}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <p
          aria-disabled={loading}
          onClick={() => {
            if (!loading) nav(Route.forgotPassword);
          }}
          className={clsx(
            'mt-4 h-[22px] cursor-pointer text-center text-lg leading-[22px] text-primary',
            loading && '!text-darkMode-gray-medium opacity-40'
          )}
        >
          {tSignIn('forgot-password-q')}
        </p>
        <ButtonDeprecated
          loading={loading}
          className="m-auto !mt-12 block"
          customDisabledClassName="!border-transparent !bg-darkMode-gray-medium !opacity-40"
        >
          {tSignIn('sign-in')}
        </ButtonDeprecated>
      </Form>
    </SignInPanel>
  );
};
